export const formationsData = [
   {
     id: 1,
     title: "RNCP Niveau 3 (BAC +2) Développeur Web",
     date: 2019,
     location: "OpenClassrooms (cours à distance)",
   },
   {
     id: 2,
     title: "Baccalauréat STI génie Electrotechnique",
     date: 2001,
     location: "Lycée Boutet de Monvel à Lunéville",
   },
   {
     id: 3,
     title: "BEP / CAP Electrotechnique",
     date: 1999,
     location: "Lycée Boutet de Monvel à Lunéville",
   }
]