export const languagesData = [
   {
     id: 1,
     language: 'css'
   },
   {
     id: 2,
     language: 'javascript'
   },
   {
     id: 3,
     language: 'php'
   },
   {
     id: 4,
     language : 'react'
   }
]